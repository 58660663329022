html,
body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  color: "#444";
  /* overflow: hidden; */
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.lastBet {
  background-color: rgba(163, 142, 170, 0.377);
  #provider {
    color: #b074ff;
  }
}
